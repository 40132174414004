<template>
  <div class="wrap">
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 24px"
        @click="onClickLeft"
      />
      <span style="font-size: 20px; color: #323233; font-weight: bold"
        >待处理危急值</span
      >
    </div>
    <van-search
      v-model="patientName"
      placeholder="请输入姓名搜索"
      @search="getdata()"
    />
    <van-loading type="spinner" v-show="loading" />
    <div
      class="wrap_list"
      v-for="(item, i) in dataList"
      :key="i"
      @click="onClickInfo(item)"
    >
      <div class="time">{{ item.resultDateTime }}</div>
      <div class="name_box">
        <span class="name">{{ item.name }}</span>
        <!-- <div class="level">危急IV</div> -->
      </div>
      <div class="list_content">患者编号：{{ item.patientId }}</div>
      <div class="list_content">
        信息：{{ item.wardDeptName }}、{{ item.bedNo }}
      </div>
      <div
        class="list_content"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
      >
        病情：{{ item.reportItemName }} {{ item.result }} {{ item.units }}（{{
          item.lowerCrisisValue
        }}-{{ item.upperCrisisValue }}）
      </div>
      <div class="detail">详情</div>
    </div>
    <!-- <van-empty image="search" description="暂无数据" v-if="dataList.length==0"/> -->
    <div class="emptyBox" v-if="showEmpty">
      <img src="../../assets/images/empty.png" />
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { getList } from "@/api/critical";
import sha1 from "js-sha1";
import { setStore, getStore } from "@/utils/localStorage.js";
export default {
  data() {
    return {
      patientName: "",
      dataList: [],
      loading: false,
      showEmpty: false,
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    onClickLeft() {
      // this.$router.push({ path: '/menu' })
      this.$router.back();
    },
    onClickInfo(item) {
      this.$router.push({
        path: "criticalInfo",
        query: { item: JSON.stringify(item) },
      });
      // this.$router.push({ name: 'criticalInfo',params:{item:JSON.stringify(item)}})
    },
    getdata() {
      this.loading = true;
      getList(this.patientName)
        .then((res) => {
          if (res.data.code == "200") {
            this.loading = false;
            this.dataList = res.data.data || [];
            if (this.dataList.length == 0) {
              this.showEmpty = true;
              this.loading = false;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-loading {
  left: 45%;
  top: 10px;
}
.wrap {
  background: #f6f6f6;
  min-height: 100vh;
  margin-bottom: -50px !important;
  padding-bottom: 10px;

  .nav_bar {
    text-align: center;
    color: #323233;
    background-color: #fff;
    padding-top: 20px;
  }

  .wrap_list {
    background-color: #fff;
    border-radius: 5px;
    margin: 15px;
    padding: 30px 15px 20px 15px;

    .time {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .name_box {
      display: flex;
      align-items: center;
      margin-top: 15px;

      .name {
        font-size: 19px;
        font-weight: bold;
        color: #333;
      }

      .level {
        width: 49px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        color: #fff;
        background: #f12622;
        border-radius: 4px;
        font-size: 13px;
        font-weight: bold;
        margin-left: 30px;
      }
    }

    .list_content {
      font-size: 13px;
      color: #666;
      margin-top: 8px;
    }

    .detail {
      color: #1578f9;
      font-size: 16px;
      text-align: right;
      font-weight: bold;
    }
  }
}

.van-icon-arrow {
  color: #999;
}

/deep/.van-badge--fixed {
  top: 25px !important;
  right: -20px !important;
}

/deep/.van-search {
  padding: 15px !important;
}
.emptyBox {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 20% auto;
  img {
    width: 50%;
    height: 50%;
    margin: 0 auto;
  }
}
</style>