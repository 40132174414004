import request from '@/router/axios';

// 危急值列表接口
export const getList = (patientName) => request({
  url: '/api/blade-medicine-tools/hospitaldata/getusercriticalvalues',
  method: 'get',
  params:{
    // userName:userName,
    // passWord:passWord,
    // tenantId:tenantId,
    patientName:patientName,
  }
})

